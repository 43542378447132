import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Page from "../components/page"
import SignUpBrokerTemplate from "../components/sign-up-shipper-template"
import { buildMeta } from "../helpers"
import LogoFullImg from "../img/logo-loadmate-full.svg"
import LogoSmallImg from "../img/logo-loadmate-small.svg"

const SignUpBrokerPage = ({
  data: { footerData, signUpBrokerPageData, statisticsData, siteData },
  location,
}) => {
  const { seo } = signUpBrokerPageData.frontmatter
  const { siteUrl } = siteData.siteMetadata

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          ...buildMeta(siteData.siteMetadata, seo, null, ""),
          ...(seo.metatags ? seo.metatags : []),
        ]}
      >
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
        {seo.schemamarkup && (
          <script type="application/ld+json">{seo.schemamarkup}</script>
        )}
      </Helmet>
      <Page
        logo={{
          full: LogoFullImg,
          small: LogoSmallImg,
          alt: "Ship.Cars LoadMate",
          link: "/loadmate",
        }}
        headerData={{ frontmatter: {} }}
        footerData={footerData}
      >
        <SignUpBrokerTemplate
          data={signUpBrokerPageData.frontmatter}
          statisticsData={statisticsData}
        />
      </Page>
    </React.Fragment>
  )
}

export default SignUpBrokerPage

export const pageQuery = graphql`
  query SignUpBrokerPage {
    ...PageFragment
    ...StatisticsFragment
    siteData: site {
      siteMetadata {
        siteUrl
        env
      }
    }
    signUpBrokerPageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/sign-up-broker.md/" }
    ) {
      frontmatter {
        title
        seo {
          title
          description
          keywords
          schemamarkup
          metatags {
            name
            content
          }
        }
      }
    }
  }
`
